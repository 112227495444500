<template>
    <div class="mb-3">
        <div class="form-step">
            <div>
                <h3><b>PENDAFTARAN SURVEY</b></h3>
                <p>Silahkan masukan inputan dibawah ini untuk melanjutkan</p>
                <hr>
            </div>
        </div>
        <div class="p-fluid">
            <div>
                <div class="formgrid grid">
                    <div class="field col-12 lg:col-6 lg:col-offset-3">
                        <label>Kategori</label>
                        <Dropdown v-model="forms.kategori" required="true" :options="dataDropdownkategori" optionLabel="name" optionValue="code" class="p-inputtext-lg" @change="convertSurvey()"/>
                    </div>
                    <div class="field col-12 lg:col-6 lg:col-offset-3">
                        <label>Nomor Whatsapp</label>
                        <InputText v-model="forms.no_wa" class="p-inputtext-lg" autofocus placeholder="Masukan Nomor Whatsapp" required="true"/>
                        <small>Contoh : 081xxxxxxxxx / 6281xxxxxxxxx / +6281xxxxxxxxx</small >
                        <small class="p-invalid" style="color: red" v-if="errorAdd.no_hp" >{{ errorAdd.no_hp[0] }}</small >
                    </div>
                </div>
            </div>
        </div>
        <div class="mt-2 text-center">
            <Button label="Reset" icon="pi pi-refresh" class="p-button-danger mr-2" @click="clearForms"/>
            <Button :loading="loading" label="Submit" icon="pi pi-link" class="p-button-primary" @click="convertNumber"/>
        </div>
    </div>
    <div class="mt-5 mb-3" style=" word-wrap: break-word;">
        <Panel header="Hasil">
            <BlockUI :blocked="loading">
                <div v-if="this.isSubmit == true">
                    <div v-if="this.isTerdaftar == false">
                        <p>Selamat Nomor Whatsapp <b>{{this.wa}}</b> berhak mengikuti Market Test Gilus <b>{{this.kegiatan}}</b> <br>
                            Silahkan klik link dibawah ini.
                        </p>
                        <p><b>Link Survey: {{this.url}}</b></p><hr>
                        <p><b>Link WA Peserta: <a :href="'https://kirimwa.id/'+this.wa">{{this.wa}}</a></b></p>
                    </div>
                    <div v-else>
                        <p>Maaf, Peserta tersebut sudah mengikuti pada kategori lain.</p>
                    </div>
                </div>
                <div v-else>
                    <p>Silahkan submit terlebih dahulu</p>
                </div>
            </BlockUI>
        </Panel>
    </div>
</template>

<script>
import { mapGetters } from "vuex";

export default {
    emits: ["submit"],
    data() {
        return {
            // loading
            loading: false,
            isSubmit: false,

            // dataDropdown
            dataDropdownkategori: [
                {name: '5 Sachet', code: '5 Sachet'},
                {name: '10 Sachet', code: '10 Sachet'},
                {name: '20 Sachet', code: '20 Sachet'},
                {name: '30 Sachet', code: '30 Sachet'},
            ],

            // addNew
            forms: {
                no_wa: null,
                kategori: '10 Sachet',
            },
            convertedNumber: '',
            numberIsTrue: false,

            wa: null,
            url: null,
            kegiatan : null,
            survey: 'survey 2',
            isTerdaftar: false,
        }
    },
    mounted() {
        this.$store.commit('setErrorAdd', {});
    },
    computed:{
        ...mapGetters(['errorAdd']),
    },
    methods: {
        convertNumber() {
            // Menghapus karakter selain angka dari nomor telepon
            const cleanedNumber = this.forms.no_wa ? this.forms.no_wa.replace(/\D/g, '') : '';

            // Mengonversi nomor dengan awalan +62 atau 62 menjadi awalan 0
            if (cleanedNumber.startsWith('62')) {
                this.convertedNumber = '0' + cleanedNumber.slice(2);
            } else if (cleanedNumber.startsWith('+62')) {
                this.convertedNumber = '0' + cleanedNumber.slice(3);
            } else if (cleanedNumber.startsWith('0')) {
               this.convertedNumber = cleanedNumber;
            } else {
                this.convertedNumber = '';
                alert('Nomor telepon tidak sesuai format yang diharapkan.');
                return;
            }

            this.saveNew();
        },
        convertSurvey(){
            if(this.forms.kategori == '5 Sachet'){
                this.survey = 'survey 1';
            }else if(this.forms.kategori == '10 Sachet'){
                this.survey = 'survey 2';
            }else if(this.forms.kategori == '20 Sachet'){
                this.survey = 'survey 3';
            }else if(this.forms.kategori == '30 Sachet'){
                this.survey = 'survey 4';
            }
        },
        // ADDNEW
        saveNew() {
            this.loading = true;

            this.axios({
                method: 'POST',
                url: process.env.VUE_APP_ROOT_API + 'web/survey/create',
                data: {
                    "status": this.survey,
                    "no_hp": this.convertedNumber,
                    "kategori": this.forms.kategori,
                },
            })
            .then((res) => {
                this.loading = false;

                if (res) {
                    this.$toast.add({ severity: 'success', summary: 'Successful', detail: 'Data Berhasil Di Generate', life: 3000,});
                    this.$store.commit('setErrors', {});
                    this.$store.commit('setErrorAdd', {});
                    
                    this.url = window.location.hostname + res.data.data.url;
                    this.wa = this.convertedNumber;
                    this.kegiatan = res.data.data.kegiatan;
                    this.isTerdaftar = res.data.data.isTerdaftar;
                    this.isSubmit = true;

                    this.$emit('submit');
                }
            })
            .catch((err) => {
                console.log(err);
                this.loading = false;
                this.$store.commit('setErrorAdd', err.response.data.data);
                this.$toast.add({ severity: 'error', summary: 'Failed', detail: 'Data Failed to Action', life: 3000,});
            });
        },
        clearForms() {
            this.forms.no_wa = null;
            this.forms.kategori = "10 Sachet";
            this.survey ="survey 2";
            this.isSubmit = false;
            this.wa = null;
            this.url = null;
            this.kegiatan = null;
            this.convertedNumber = null;
        },
    }
}
</script>

<style>
.form-step {
    width: 100%;
    margin: 0 auto;
    text-align: center;
}
</style>