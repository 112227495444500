<template>
    <Error :errors="errors"/>
    <div class="grid">
		<div class="col-12">
            <div class="card">

                <Fieldset legend="Pendaftaran" :toggleable="true" :collapsed="true" class="mb-3"> 
                    <Daftar @submit="getDataTable()" ref="daftar"/>
                </Fieldset>

                <Fieldset legend="Filter" :toggleable="true" :collapsed="true" class="mb-3">
                    <Filter @submit="getDataTable()" ref="filter"/>
                </Fieldset>

                <DataTable :value="dataTable" responsiveLayout="scroll" :loading="loading" dataKey="number" @sort="onSort($event)" :resizableColumns="true"
                    v-model:expandedRows="expandedRows">
                    <template #header>
                        <div class="flex flex-column md:flex-row md:justify-content-between md:align-items-center">
                            <div>
                                <Button :loading="loadingExcel" label="Export Excel" icon="pi pi-file-excel" class="p-button p-button-success mr-2 my-1 inline-block" @click="exportExcelCSV('xlsx')" />
                                <Button :loading="loadingCsv" label="Export CSV" icon="pi pi-file-excel" class="p-button p-button-success mr-2 my-1 inline-block" @click="exportExcelCSV('csv')" />
                            </div>
                            <span class="block mt-2 md:mt-0 p-input-icon-left">
                                <i class="pi pi-search" />
                                <InputText v-model="search" placeholder="Cari..." @keyup.enter="getDataTable" />
                            </span>
                        </div>
                    </template>
                    <Column :expander="true" :headerStyle="{'width': '3rem'}" />
                    <Column field="number" header="No." :style="{width:'20px'}" >
                        <template #body="slotProps">
                        {{ slotProps.data.number }}
                        </template>
                    </Column>
                    <Column field="noHp" header="Peserta">
                        <template #body="slotProps">
                            {{slotProps.data.peserta?.noHp}}
                        </template>
                    </Column>
                    <Column field="status" header="Kategori">
                        <template #body="slotProps">
                            {{ convertSurvey(slotProps.data.status)}}
                        </template>
                    </Column>
                    <Column field="isSurvey" header="Isi Survey">
                        <template #body="slotProps">
                            <Button label="Belum" class="p-button-rounded p-button-danger" v-if="slotProps.data.isSurvey == 0"/>
                            <Button label="Sudah" class="p-button-rounded" v-else/>
                        </template>
                    </Column>
                    <Column field="isHadiah" header="Hadiah">
                        <template #body="slotProps">
                            <Button icon="pi pi-times" class="p-button-rounded p-button-danger" v-if="slotProps.data.isHadiah == 0"/>
                            <Button icon="pi pi-check" class="p-button-rounded p-button-success" v-else/>
                        </template>
                    </Column>
                    <Column field="terimaHadiah" header="Terima Hadiah">
                        <template #body="slotProps">
                            <Button icon="pi pi-times" class="p-button-rounded p-button-danger" v-if="slotProps.data.terimaHadiah == 0"/>
                            <Button icon="pi pi-check" class="p-button-rounded p-button-success" v-else/>
                        </template>
                    </Column>
                    <Column field="keteranganHadiah" header="Keterangan Hadiah">
                        <template #body="slotProps">
                            {{slotProps.data.keteranganHadiah}}
                        </template>
                    </Column>
                    <Column field="waktuTerimaHadiah" header="Waktu Terima Hadiah">
                        <template #body="slotProps">
                            {{slotProps.data.waktuTerimaHadiah}}
                        </template>
                    </Column>
                    <Column field="kode_agent" header="Pemberi Hadiah">
                        <template #body="slotProps">
                            <div v-if="slotProps.data.userweb_id">
                                {{slotProps.data.userweb?.kode_agent}} - {{slotProps.data.userweb?.userweb_nama}}
                            </div>
                        </template>
                    </Column>
                    <Column header="Detail Tahap Pertama" headerStyle="min-width:10rem;">
                        <template #body="slotProps">
                            <Button icon="pi pi-search" class="p-button-rounded p-button-primary mr-2" @click="detailItem(slotProps.data)" />
                        </template>
                    </Column>
                     <template #expansion="slotProps">
                        <div class="orders-subtable">
                            <h5><i>{{slotProps.data.peserta?.noHp}}</i></h5>
                            <div v-if="slotProps.data.status == 'survey 1'">
                                <ul>
                                    <li>Pada tahap 1 silahkan menggunakan link ini  <a>{{url}}/survey1/{{slotProps.data.peserta?.noHp}}</a></li>
                                    <li v-if="slotProps.data.terimaHadiah == 1">Pada tahap 2 silahkan menggunakan link ini  <a>{{url}}/survey/1/{{slotProps.data.peserta?.noHp}}/2</a></li>
                                    <li v-if="slotProps.data.terimaHadiah == 1">Pada tahap 3 silahkan menggunakan link ini  <a>{{url}}/survey/1/{{slotProps.data.peserta?.noHp}}/3</a></li>
                                    <li v-else> Maaf, peserta belum dapat mengikuti selanjutnya karena belum/tidak mendapatkan hadiah.</li>
                                </ul>
                            </div>
                            <div v-if="slotProps.data.status == 'survey 2'">
                                <ul>
                                    <li>Pada tahap 1 silahkan menggunakan link ini  <a>{{url}}/survey2/{{slotProps.data.peserta?.noHp}}</a></li>
                                    <li v-if="slotProps.data.terimaHadiah == 1">Pada tahap 2 silahkan menggunakan link ini  <a>{{url}}/survey/2/{{slotProps.data.peserta?.noHp}}/2</a></li>
                                    <li v-if="slotProps.data.terimaHadiah == 1">Pada tahap 3 silahkan menggunakan link ini  <a>{{url}}/survey/2/{{slotProps.data.peserta?.noHp}}/3</a></li>
                                    <li v-else> Maaf, peserta belum dapat mengikuti selanjutnya karena belum/tidak mendapatkan hadiah.</li>
                                </ul>
                            </div>
                            <div v-if="slotProps.data.status == 'survey 3'">
                                <ul>
                                    <li>Pada tahap 1 silahkan menggunakan link ini  <a>{{url}}/survey3/{{slotProps.data.peserta?.noHp}}</a></li>
                                    <li v-if="slotProps.data.terimaHadiah == 1">Pada tahap 2 silahkan menggunakan link ini  <a>{{url}}/survey/3/{{slotProps.data.peserta?.noHp}}/2</a></li>
                                    <li v-if="slotProps.data.terimaHadiah == 1">Pada tahap 3 silahkan menggunakan link ini  <a>{{url}}/survey/3/{{slotProps.data.peserta?.noHp}}/3</a></li>
                                    <li v-if="slotProps.data.terimaHadiah == 1">Pada tahap 4 silahkan menggunakan link ini  <a>{{url}}/survey/3/{{slotProps.data.peserta?.noHp}}/4</a></li>
                                    <li v-else> Maaf, peserta belum dapat mengikuti selanjutnya karena belum/tidak mendapatkan hadiah.</li>
                                </ul>
                            </div>
                            <div v-if="slotProps.data.status == 'survey 4'">
                                <ul>
                                    <li v-if="slotProps.data.terimaHadiah == 1">Pada tahap 1 silahkan menggunakan link ini  <a>{{url}}/survey4/{{slotProps.data.peserta?.noHp}}</a></li>
                                    <li v-if="slotProps.data.terimaHadiah == 1">Pada tahap 2 silahkan menggunakan link ini  <a>{{url}}/survey/4/{{slotProps.data.peserta?.noHp}}/2</a></li>
                                    <li v-else> Maaf, peserta belum dapat mengikuti selanjutnya karena belum/tidak mendapatkan hadiah.</li>
                                </ul>
                            </div>
                            <DataTable :value="slotProps.data.detail" :resizableColumns="true">
                                <Column header="Detail" :headerStyle="{'width': '3rem'}">
                                    <template #body="slotProps">
                                        <Button icon="pi pi-search" class="p-button-rounded p-button-primary mr-2" @click="detailItem2(slotProps.data)" />
                                    </template>
                                </Column>
                                <Column field="tahap" header="Tahap">
                                    <template #body="slotProps">
                                        <div v-if="!slotProps.data.tahap">
                                            1
                                        </div>
                                        <div v-else>
                                            {{slotProps.data.tahap}}
                                        </div>
                                    </template>
                                </Column>
                                <Column field="waktuSurvey" header="Waktu Survey">
                                    <template #body="slotProps">
                                        {{slotProps.data.waktuSurvey}}
                                    </template>
                                </Column>
                            </DataTable>
                        </div>
                    </template>
                    <template #empty>
                        No data found.
                    </template>
                    <template #loading>
                        Loading data. Please wait.
                    </template>
                    <template #footer>
                        In total there are {{totalItemsCount ? totalItemsCount : 0 }} data.
                    </template>
                </DataTable>

                <Paginator v-model:rows="rows" v-model:first="offset" :totalRecords="totalItemsCount" :rowsPerPageOptions="[10,20,30]">
                    <template>
                        {{totalItemsCount}}
                    </template>
                </Paginator>

                <Detail :item="item" ref="detail"/>
                <Detail2 :item="item2" ref="detail2"/>

            </div>
        </div>
    </div>
</template>


<script>
import moment from 'moment';
import { mapGetters } from "vuex";
import { numberingDatatable } from '../../../utils/helpers';
import Daftar from '../../../components/new/aktifitas/Daftar.vue';
import Filter from '../../../components/new/aktifitas/Filter.vue';
import Detail from '../../../components/new/aktifitas/Detail.vue';
import Detail2 from '../../../components/new/aktifitas/Detail2.vue';
import Error from '../../../components/Error.vue';

export default {
    components: {
		'Daftar': Daftar,
		'Filter': Filter,
		'Detail': Detail,
		'Detail2': Detail2,
		'Error': Error,
	},
    data() {
        return {
            // loading
            loading: false,
            loadingExcel: false,
            loadingCsv: false,

            // detail
            item: {},
            item2: {},

            //datatables
            data: null,
            dataTable: null,
            rows: 10,
            search: null,
            offset: null,
            field: null,
            sort: null,
            page: null,
            totalItemsCount: 0,

            //expand datatable
            expandedRows: [],

            survey: null,
            url: window.location.hostname,

        }
    },
    mounted() {
        this.getDataTable();
        this.$store.commit('setErrors', {});
    },
    watch: {
        offset() {
            this.getDataTable();
        },
        rows() {
            this.getDataTable();
        }
    },
    computed:{
        ...mapGetters(['errors']),
    },
    methods: {
        formatDate(value){
            if (value) {
                return moment.utc(value).format('DD-MM-YYYY')
            }
        },
        convertSurvey(kategori){
            if(kategori == 'survey 1'){
                this.survey = '5 Sachet';
            }else if(kategori == 'survey 2'){
                this.survey = '10 Sachet';
            }else if(kategori == 'survey 3'){
                this.survey = '20 Sachet';
            }else if(kategori == 'survey 4'){
                this.survey = '30 Sachet';
            }
            return this.survey
        },
        // DETAIL
		detailItem(item) {

			this.item = item;
            
            this.$refs.detail.detailItemDialog = true;
		},
		detailItem2(item) {

			this.item2 = item;
            
            this.$refs.detail2.detailItemDialog = true;
		},
        // DATATABLE
        getDataTable(){
          
            this.loading=true;
            this.$refs.filter.loading = true;
            this.page = (this.offset / this.rows) + 1;

            this.axios({
                method: 'GET',
                url: process.env.VUE_APP_ROOT_API + 'web/survey',
                params: {
                    "search" : this.search,
                    "per_page" : this.rows,
                    "page" : this.page,
                    "order_by" : this.field,
                    "sort_by" : this.sort,
                    "status" : this.$refs.filter.survey,
                }
            })
            .then(res => {
              this.data = res.data.data.data;
              this.dataTable = numberingDatatable(res.data.data.data, this.page, this.rows);
              this.totalItemsCount = res.data.data.total;
              this.rows = parseInt(res.data.data.per_page);
              this.loading=false;
              this.$refs.filter.loading = false;
            })
            .catch((err) => {
                console.log(err);
                this.loading=false;
                this.$refs.filter.loading = false;
            });
         
        },
        onSort(event) {
            this.field = event.sortField;
            this.sort = event.sortOrder == '1' ? 'ASC' : 'DESC';
            this.getDataTable();
        },
        // EXPORT
        exportExcelCSV(ext){

            if(ext == 'xlsx'){
                this.loadingExcel=true;
            }else if(ext == 'csv'){
                this.loadingCsv=true;
            }

            this.axios({
                method: 'GET',
                url: process.env.VUE_APP_ROOT_API + 'web/survey/export',
                responseType: 'blob',
                params: {
                    "ext" : ext,
                    "status" : this.$refs.filter.survey,
                }
            })
            .then(response => {
                let fileUrl = window.URL.createObjectURL(response.data);
                let fileLink = document.createElement('a');

                fileLink.href = fileUrl;

                fileLink.setAttribute('download', 'Report Survey Export.' + ext);
                
                document.body.appendChild(fileLink)

                fileLink.click();
                
                if(ext == 'xlsx'){
                    this.loadingExcel=false;
                }else if(ext == 'csv'){
                    this.loadingCsv=false;
                }

            })
            .catch((err) => {
                console.log(err);
                
                if(ext == 'xlsx'){
                    this.loadingExcel=false;
                }else if(ext == 'csv'){
                    this.loadingCsv=false;
                }
            });
        },
    }
}
</script>
