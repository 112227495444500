<template>
    <div>
        <div class="p-fluid">
            <div class="formgrid grid">
                 <div class="field col-12 md:col-6 lg:col-3">
                    <label>Kategori</label>
                    <Dropdown v-model="filters.kategori" required="true" :options="dataDropdownkategori" optionLabel="name" optionValue="code" placeholder="Pilih Kategori" @change="convertSurvey()"/>
                 </div>
            </div>
        </div>
        <Button :loading="loading" label="Filter" icon="pi pi-search" class="p-button-warning mr-2 my-1" @click="getDataTable" />
    </div>
</template>

<script>

export default {
    data() {
        return {
            // loading
            loading: false,

            // dataDropdown
            dataDropdownkategori: [
                {name: '5 Sachet', code: '5 Sachet'},
                {name: '10 Sachet', code: '10 Sachet'},
                {name: '20 Sachet', code: '20 Sachet'},
                {name: '30 Sachet', code: '30 Sachet'},
            ],

            // filter
            filters: {
                kategori: '5 Sachet',
            },

            survey: 'survey 1'
        }
    },
    mounted() {
        this.filters.kategori = '5 Sachet';
    },
    created(){
    },
    watch: {
    },
    computed:{
    },
    methods: {
        convertSurvey(){
            if(this.filters.kategori == '5 Sachet'){
                this.survey = 'survey 1';
            }else if(this.filters.kategori == '10 Sachet'){
                this.survey = 'survey 2';
            }else if(this.filters.kategori == '20 Sachet'){
                this.survey = 'survey 3';
            }else if(this.filters.kategori == '30 Sachet'){
                this.survey = 'survey 4';
            }
        },
        getDataTable(){
            this.$emit('submit');
        }
    }
}
</script>

